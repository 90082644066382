import { Injectable } from '@angular/core';

// Root Singleton Service
import { StorageHelperService } from 'app/core/services/storage-helper.service';

// todo get from backend
export const userPermissions = {
  settingsView: 'settings.view',
  dialogsView: 'dialogs.view',
  conversationsView: 'conversations.view',
  reportingExport: "reporting.export",
  clientReports: "client.reports",
  hangfireView: 'dev.hangfire',
};

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {
  userPermissions = userPermissions;
  
  constructor(private storageHelper: StorageHelperService) { }

  hasPermission(userPermission: string): boolean {
    if (this.storageHelper && this.storageHelper.userObject && this.storageHelper.userObject.permissions && this.storageHelper.userObject.permissions.indexOf(userPermission) > -1) {
      return true;
    }
    return false;
  }
}
