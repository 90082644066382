import { Component,  Input, Output, EventEmitter } from '@angular/core';

// Root Singleton Service
import { NavigationService } from "app/core/services";
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {

  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  @Output() closeMenu: EventEmitter<void> = new EventEmitter();

  constructor(
    private navService: NavigationService,
    private router: Router) {}

  openSidebarRight(): void {
    if (this.navService.sidebarRightOpen == true) {
      this.navService.sidebarRightOpen = false;
    } else {
      this.navService.sidebarRightOpen = true;
      this.navService.sidebarRightOpened = true;
    }
  }

  closeMainMenu($event){
    this.closeMenu.emit();
  }

  isLinkActive(item) {
    return this.router.url.includes(item.state);
  }
}
