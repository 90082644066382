import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserPermissionsService } from '../../core/services';
import { BotEnvironments } from '../../domain/constants/BotEnvironments';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(private router: Router, private userPermissionsService: UserPermissionsService) {}

  storeCurrentUrl(url: string): void {
    // store the current URL for later use
    sessionStorage.setItem('redirectUrl', url);
  }

  redirectToLogin(): void {
    // redirect the user to the login page
    this.router.navigate(['/sessions/signin']);
  }

  redirectToDashboard(environment: string): void {
    // redirect the user to the dashboard
    this.router.navigate([environment + '/reports']);
  }

  redirectToSavedRoute(): void {
    // redirect the user to the stored URL
    if (sessionStorage.getItem('redirectUrl')) {
      this.router.navigate([sessionStorage.getItem('redirectUrl')]);
    } else {
      setTimeout(() => {
        if (JSON.parse(sessionStorage.getItem('appSettings'))?.urlLoaded?.toLowerCase() === '/') {
          this.redirectToDashboard('test');
        }
      }, 200);
    }
  }

  isAllowed(url: string): boolean {
    if (
      url === `/${BotEnvironments.test.toLowerCase()}/dialogues/view` ||
      url === `/${BotEnvironments.prod.toLowerCase()}/dialogues/view`
    ) {
      if (!this.userPermissionsService.hasPermission(this.userPermissionsService.userPermissions.dialogsView)) {
        return false;
      }
    }
    return true;
  }
}
