<mat-toolbar id="main-top-bar" class="topbar">

  <app-breadcrumb></app-breadcrumb>
  <span class="bot-name" *ngIf="hideVASelector == false && (isBotCreated$ | async)">
    {{(virtualAgentName$ | async)}}
  </span>

  <mat-select *ngIf="((bots$ | async)?.length > 1 && hideVASelector == false) && (isBotCreated$ | async)" [matMenuTriggerFor]="botMenu" [style.width]="'min-content'">
  </mat-select>
  <mat-menu #botMenu="matMenu">
    <button *ngFor="let bot of bots$ | async" mat-menu-item (click)="setBot(bot.id)">
      <span>{{ bot.name }}</span>
    </button>
  </mat-menu>

  <div style="margin-left: 31px;" *ngIf="(isBotCreated$ | async)">
    <mat-button-toggle-group
      class="mat-button-toggle-group mat-button-toggle-group-appearance-standard toggle-checked-buttons"
      style="font-size: 14px;font-weight: normal;" #group="matButtonToggleGroup" [value]="(environment$ | async)"
      (change)="setEnvironment(group.value)">
      <mat-button-toggle id="env-production" value="Prod">
        <span>Production</span>
      </mat-button-toggle>
      <mat-button-toggle id="env-test" value="Test">
        <span>Test</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>


  <span fxFlex></span>

  <!-- <button mat-icon-button *ngIf='hubStatus' (click)='disconnectHub()'> -->
  <div *ngIf='!hubStatus && (isBotCreated$ | async)'  class="topbar-div-right">
    <mat-icon matTooltip="All systems operational" >wifi_tethering</mat-icon>
  </div>
  <!-- </button> -->

  <div *ngIf='hubStatus && (isBotCreated$ | async)' class="connection-issues">
    Connection problems detected
  </div>

  <div *ngIf='hubStatus && (isBotCreated$ | async)' class="topbar-div-right">
    <mat-icon matTooltipClass="tooltip-red" class='red-icon'>portable_wifi_off</mat-icon>
  </div>

  <div class="topbar-button-right">
    <ebo-notifications></ebo-notifications>
  </div>

  <button *ngIf="(hasSettingsPermission$ | async) && (isBotCreated$ | async)" mat-icon-button [style.overflow]="'visible'" class="topbar-button-right"
          (click)="settings()">
    <mat-icon>settings</mat-icon>
  </button>

  <!-- Top left user menu -->
  <button mat-icon-button id="usermenu" [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <ngx-avatars size="40" [src]="(picture$ | async)" [name]="(fullname$ | async)"></ngx-avatars>
  </button>
  <mat-menu #accountMenu="matMenu">
    <ng-container *ngIf="!(isAzure$ | async)">
      <button id="reset-password" mat-menu-item (click)="resetOurPassword()">
        <mat-icon>lock</mat-icon>
        <span>Reset password</span>
      </button>
    </ng-container>
    <button id="signout" mat-menu-item (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>
</mat-toolbar>
