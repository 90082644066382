import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../../core/services';
import {BehaviorSubject, Observable} from 'rxjs';
import {FeatureFlagModel} from '../models/featureFlag.model';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private readonly apiUrl: string;
    private featureFlagsSubject = new BehaviorSubject<FeatureFlagModel[]>(null);
    currentFeatureFlags = this.featureFlagsSubject.asObservable();


    constructor(private httpClient: HttpClient, private apiService: ApiService) {
        this.apiUrl = `${this.apiService.apiUrl}`;
    }

    getFeatureFlags(): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}appsettings/type`, {params: {type: 'FeatureFlag'}});
    }

    getFeatureFlagKey(key: string): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}appsettings/key`, {params: {key}});
    }

    createFeatureFlag(requestBody: any): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}appsettings/create`, requestBody);
    }

    postFeatureFlagList(requestBody: any): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}appsettings/createmultiple`, requestBody);
    }

    putFeatureFlag(requestBody: any): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}appsettings/update`, requestBody);
    }

    deleteFeatureFlag(id: string): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}appsettings/delete`, {params: {id}});
    }

    // BehaviorSubject functions
    changeFeatureFlags(featureFlags: FeatureFlagModel[]): void {
        this.featureFlagsSubject.next(featureFlags);
    }
}
