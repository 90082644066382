import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {environment} from 'environments/environment';
// NgRx
import {select, Store} from '@ngrx/store';
import * as fromRoot from 'app/core/store';
import * as fromAuth from 'app/auth/store';
// RxJs
import {Observable, Subscription} from 'rxjs';
// 3rd
import PerfectScrollbar from 'perfect-scrollbar';

// Root Singleton Service
import {LayoutService} from 'app/core/services/layout.service';
import {NavigationService} from 'app/core/services/navigation.service';
import {UserPermissionsService} from 'app/core/services/user-permissions.service';
import {filter} from 'rxjs/operators';
import {FeatureFlagService} from '../../../views/settings/feature-flag/services/feature-flag.service';
import {FeatureFlagModel} from '../../../views/settings/feature-flag/models/featureFlag.model';

@Component({
  selector: 'ebo-sidebar-side',
  templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  public layoutConf: any;
  public appVersion = environment.appVersion;
  public environment$: Observable<string>;
  private sidebarPS: PerfectScrollbar;
  private menuItemsSub: Subscription;
  private featureFlags: FeatureFlagModel[];
  public hasBeenClosed: boolean;

  constructor(
    private navService: NavigationService,
    private layout: LayoutService,
    private userPermissionsService: UserPermissionsService,
    private store: Store<fromRoot.AppState>,
    private featureFlag: FeatureFlagService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.matchMedia(`(max-width: 768px)`).matches) {
      if (!this.hasBeenClosed) {
        this.layout.publishLayoutChange({
          sidebarStyle: 'compact'
        }, {transitionClass: true});
        this.hasBeenClosed = true;
      }
    }else {
      if (this.hasBeenClosed) {
        this.hasBeenClosed = false;
      }
    }
  }

  ngOnInit(): void {
    this.getFeatureFlags();
    this.environment$ = this.store.pipe(select(fromAuth.getEnvironment));

    this.environment$
      .pipe(
        filter(x => x != null)
      )
      .subscribe(env => {
        this.navService.defaultMenu.forEach(e => {
          // right now hardcoded this has to match routes with :environment
          if ([
            'Performance Management',
            'Reports',
            'Conversations',
            'Conversations new',
            'Dialog designer',
            'Test virtual agent',
            'Waiting list'].includes(e.name)) {
            const parts = e.state.split('/');
            e.state = `${env.toLowerCase()}/${parts[parts.length - 1]}`;
          }
        });

        this.navService.menuItems.next(this.navService.defaultMenu);
      });


    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      if (this.featureFlags && this.featureFlags.length > 0) {
        this.menuItems = menuItem;
        // Checks item list has any icon type.
        this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;

        // disable items that the user doesn't have access to
        menuItem.forEach(x => {
          if (x.featureFlag) {
            x.disabled = true;
            if (this.featureFlags.filter(y => y.key === x.featureFlag && y.value === 'true').length > 0) {
              x.disabled = false;
            }
          }
          if (x.permission) {
            if (!this.userPermissionsService.hasPermission(x.permission)) {
              x.disabled = true;
            } else {
              x.disabled = false;
            }
          }
        });
      } else {
        this.menuItems = menuItem;
        this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
        menuItem.forEach(x => {
          if (x.featureFlag) {
            x.disabled = true;
          }
          if (x.permission) {
            if (!this.userPermissionsService.hasPermission(x.permission)) {
              x.disabled = true;
            } else {
              x.disabled = false;
            }
          }
        });
      }
    });

    this.layoutConf = this.layout.layoutConf;
  }

  ngOnDestroy(): void {
    if (this.sidebarPS) {
      this.sidebarPS.destroy();
    }
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  toggleCollapse(): void {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true});
  }

  getFeatureFlags(): void {
    this.featureFlag.currentFeatureFlags.subscribe(data => {
      if (data && data.length > 0) {
        this.featureFlags = data;
        this.navService.menuItems.next(this.navService.defaultMenu);
      }
    });
  }
}
