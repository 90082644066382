import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Root Singleton Services
import { ConnectionResolver, LayoutService, ThemeService } from 'app/core/services';

// NgRx
import { select, Store } from '@ngrx/store';
import * as authActions from 'app/auth/store/auth.actions';
import * as fromRoot from 'app/core/store';
import * as fromAuth from 'app/auth/store';

// RxJs
import { Observable } from 'rxjs';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { MatDialog } from '@angular/material/dialog';
import {getAccountType, isAzure} from 'app/auth/store';

@Component({
  selector: 'ebo-header-side',
  templateUrl: './header-side.template.html',
  styleUrls: ['./header-side.component.scss']
})
export class HeaderSideComponent implements OnInit {

  @Input() notificPanel;

  public egretThemes;
  public layoutConf: any;
  public hubStatus = true;
  public hideVASelector = false;

  public environment$: Observable<string>;
  public virtualAgentName$: Observable<string>;
  public fullname$: Observable<string>;
  public picture$: Observable<string>;
  public hasSettingsPermission$: Observable<boolean>;
  public bots$: Observable<{ id: number, name: string }[]>;
  public userId$: Observable<string>;
  isBotCreated$: Observable<boolean>;
  isAzure$: Observable<boolean>;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public dialog: MatDialog,
    private router: Router,
    private connectionResolver: ConnectionResolver,
    private store: Store<fromRoot.AppState>,
  ) {
      // Hiding VA Selector if in conversation route
      this.router.events.subscribe(() => {
        this.hideVASelector = this.router.url.includes('/conversations');
      });
  }


  ngOnInit(): void {
    this.environment$ = this.store.pipe(select(fromAuth.getEnvironment));
    this.virtualAgentName$ = this.store.pipe(select(fromAuth.getVirtualAgentName));
    this.bots$ = this.store.pipe(select(fromAuth.getBots));
    this.userId$ = this.store.pipe(select(fromAuth.getUserId));
    this.fullname$ = this.store.pipe(select(fromAuth.getFullName));
    this.picture$ = this.store.pipe(select(fromAuth.getPicture));
    this.hasSettingsPermission$ = this.store.pipe(select(fromAuth.hasPermission, {permission: 'settings.view'}));
    this.isBotCreated$ = this.store.pipe(select(fromAuth.isBotCreated));
    this.isAzure$ = this.store.pipe(select(fromAuth.isAzure));

    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.connectionResolver.conversationHubStatus$.subscribe((status: boolean) => {
      this.hubStatus = status;
    });
  }

  setEnvironment(environment: string): void {
      this.store.dispatch(authActions.setEnvironment({payload:{ environment }}));
  }

  setBot(botId: number): void {
      this.store.dispatch(authActions.setBotId({payload:{botId}}));
  }

  signOut(): void {
    this.store.dispatch(authActions.logout());
  }

  resetOurPassword(): void {
    this.dialog.open(ResetPasswordComponent, {
      panelClass: 'ebo-small-dialog',
      disableClose: true
    });
  }

  settings(): void {
    this.router.navigateByUrl('/settings');
  }
}
