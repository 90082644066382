import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Root Singleton Service
import { UserPermissionsService } from 'app/core/services/user-permissions.service';

interface IMenuItem {
    type: string;       // Possible values: link/dropDown/icon/separator/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    permission: string;
    featureFlag?: string;
}

interface IChildItem {
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarRightOpen = false;
    public sidebarRightOpened = false;

    constructor(private userPermissionsService: UserPermissionsService) {
    }

    defaultMenu: IMenuItem[] = [
        {
            name: 'Performance Management',
            type: 'link',
            tooltip: 'Reporting',
            icon: 'bar_chart',
            state: 'reports',
            permission: ''
        },
        {
            name: 'Dialog designer',
            type: 'link',
            tooltip: 'Dialog designer',
            icon: 'format_shapes',
            state: 'dialogues',
            permission: this.userPermissionsService.userPermissions.dialogsView
        },
        // {
        //     name: 'Conversations',
        //     type: 'link',
        //     tooltip: 'Conversations',
        //     icon: 'chat',
        //     state: 'conversations',
        //     permission: this.userPermissionsService.userPermissions.conversationsView
        // },
        {
          name: 'Conversations',
          type: 'link',
          tooltip: 'Conversations',
          icon: 'chat',
          state: 'conversations',
          permission: this.userPermissionsService.userPermissions.conversationsView
        },
        {
            name: 'Test virtual agent',
            type: 'link',
            tooltip: 'Test virtual agent',
            icon: 'play_circle_filled',
            state: 'chat',
            permission: ''
        },
        {
            name: 'Reports',
            type: 'link',
            tooltip: 'Reporting',
            icon: 'reorder',
            state: 'client-reports',
            permission: ''
        },
        {
            name: 'Waiting list',
            type: 'link',
            tooltip: 'Waiting list',
            icon: 'pending_actions',
            state: 'waiting-list',
            permission: '',
            featureFlag: 'waitingList'
        },
        {
            name: 'Support',
            type: 'extLink',
            tooltip: 'Support',
            icon: 'live_help',
            state: 'mailto:support@ebo.ai?subject=Issue%2FSupport%20Query&body=Update%20subject%20line%20if%20required%20and%20report%20issue%20%2F%20support%20question%20below.',
            permission: ''
        },
        {
            name: 'Hangfire Jobs',
            type: 'extLink',
            tooltip: 'Hangfire jobs',
            icon: 'assignment',
            state: 'hangfire',
            permission: this.userPermissionsService.userPermissions.hangfireView
        }
        // {
        //   name: 'DOC',
        //   type: 'extLink',
        //   tooltip: 'Documentation',
        //   icon: 'library_books',
        //   state: 'http://www.ebo.ai/'
        // }
    ];

    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = 'Frequently Accessed';
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // Customizer component uses this method to change menu.
    // You can remove this method and customizer component.
    // Or you can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string): void {
        this.menuItems.next(this.defaultMenu);
    }
}
