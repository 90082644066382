<div class="notifications-box">
  <ng-container *ngIf="notifCounter === 0; else active">
    <button mat-icon-button class="topbar-button-right img-button" id="notifications-box">
      <mat-icon aria-label="Notifications" class="notif" id="notifications-icon" matTooltip="All notifications"> notifications </mat-icon>
    </button>
  </ng-container>
  <ng-template #active>
    <button mat-icon-button class="topbar-button-right img-button" id="notifications-box-active">
      <mat-icon
        aria-label="Notifications active"
        class="notif"
        [matBadge]="notifCounter"
        [matBadgeHidden]="notifCounter === 0"
        matBadgeSize="small"
        id="notifications-icon-active"
        [matTooltip]="
          notifCounter === 0 ? 'All notifications' : 'You have ' + notifCounter + ' unread notification' + (notifCounter > 1 ? 's' : '')
        "
      >
        notifications_active
      </mat-icon>
    </button>
  </ng-template>
  <ng-container *ngIf="isTasksLoad">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
  <ng-container *ngIf="openMenu">
    <div class="notif-container mat-elevation-z5">
      <ng-container *ngIf="notifications.length > 0; else noResult">
        <ng-container *ngFor="let n of notifications; let index = index">
          <div class="notif-item" (click)="readItem(n)">
            <mat-icon class="notif-icon" [color]="n.color">{{ n.icon }}</mat-icon>
            <div class="mat-list-text">
              <span class="message">{{ n.message }}</span>
              <small class="time text-muted">{{ n.time | date : 'dd/MM/yyyy HH:mm' }}</small>
              <ng-container *ngIf="index !== notifications.length - 1">
                <mat-divider></mat-divider>
              </ng-container>
            </div>
            <span class="dot" *ngIf="!n.read"></span>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noResult>
        <span class="no-result"> There is no notification yet. </span>
      </ng-template>
    </div>
  </ng-container>
</div>
