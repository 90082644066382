import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AvatarModule } from 'ngx-avatars';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './components/header-side/header-side.component';
import { SidebarSideComponent } from './components/sidebar-side/sidebar-side.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';

// ALL TIME REQUIRED
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

// DIRECTIVES
import { FontSizeDirective } from './directives/font-size.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { AppDropdownDirective } from './directives/dropdown.directive';
import { DropdownAnchorDirective } from './directives/dropdown-anchor.directive';
import { DropdownLinkDirective } from './directives/dropdown-link.directive';
import { AccessByRoleDirective } from './directives/access-by-role.directive';

// PIPES
import { ExcerptPipe } from './pipes/excerpt.pipe';

// SERVICES
import { UploadService } from 'app/shared/services/upload.service';
import { RandomService } from 'app/shared/services/random.service';
import { BotService } from 'app/views/bots/bot.service';
import { UsersService } from '../views/users/users.service';

// COMPONENTS
import { WebchatComponent } from 'app/views/dialogues/blocks/webchat/webchat.component';
import { EboInspectorChatCardComponent } from 'app/views/chat/chat-cards/ebo-inspector/ebo-inspector.component';
import { BotDetailChatCardComponent } from 'app/views/chat/chat-cards/bot-detail/bot-detail.component';
import { FileUploadComponent } from 'app/shared/components/file-upload/file-upload.component';
import { MaterialModule } from 'app/shared/material.module';
import { ActiveFeatureDirective } from './directives/active-feature.directive';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const classesToInclude = [
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  SidebarRightComponent,
  WebchatComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  AccessByRoleDirective,
  ExcerptPipe,
  EboInspectorChatCardComponent,
  BotDetailChatCardComponent,
  FileUploadComponent,
  ActiveFeatureDirective,
  ResetPasswordComponent
];

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MaterialModule,
    PerfectScrollbarModule,
    ReactiveFormsModule
  ],
  declarations: classesToInclude,
  exports: classesToInclude
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [BotService, RandomService, UploadService, UsersService]
    };
  }
}
