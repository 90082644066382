import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

// NgRx
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/core/store';

// Root Singleton Services
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { LoginResponseObj } from '../../domain/loginResponseObj';
import { RouteService } from './route.service';
import { UserPermissionsService } from '../../core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  currentUser: LoginResponseObj;
  userObjectKey = 'userObject';

  constructor(
    private store: Store<fromRoot.AppState>,
    private sessionStorage: SessionStorageService,
    private routeService: RouteService,
    private router: Router,
    private userPermissionsService: UserPermissionsService
  ) {
    const userObject = new LoginResponseObj();
    const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
    this.currentUser = userObject.parseFromSessionStorage(sessionStorageItem);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // If not authenticated, send to login
    // check if there is user info on the session storage
    const user = this.sessionStorage.get(this.userObjectKey);
    if (!user) {
      if (window.location.pathname.includes('/sessions/reset-password')) {
        const emailParam = new URLSearchParams(window.location.search).get('email');
        const codeParam = decodeURIComponent(new URLSearchParams(window.location.search).get('code'));

        this.router.navigateByUrl(`${window.location.pathname}?email=${emailParam}&code=${codeParam}`);
      } else {
        this.handleUnauthenticatedUser(state);
        return;
      }
    } else {
      // check if the user has permission to access the route
      if (this.checkRoutes(state.url)) {
        return true;
      } else {
        this.routeService.redirectToLogin();
        return;
      }
    }
  }

  handleUnauthenticatedUser(state: RouterStateSnapshot): void {
    this.routeService.storeCurrentUrl(state.url);
    this.routeService.redirectToLogin();
  }

  checkRoutes(url: string): boolean {
    return this.routeService.isAllowed(url);
  }
}
