import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

// NgRx
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/core/store';

// Root Singleton Services
import { UserPermissionsService } from 'app/core/services/user-permissions.service';
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { UsersService } from '../../views/users/users.service';
import { LoginResponseObj } from '../../domain/loginResponseObj';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  public authToken;
  currentUser: LoginResponseObj;
  userObjectKey = 'userObject';

  constructor(
    private store: Store<fromRoot.AppState>,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private userPermissionsService: UserPermissionsService,
    private userService: UsersService)
  {
    const userObject = new LoginResponseObj();
    const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
    this.currentUser = userObject.parseFromSessionStorage(sessionStorageItem);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>
  {
    return this.userService.getUserRoles(this.currentUser.userId).pipe(
      first(),
      map((obj) => {
        // @ts-ignore
        if (obj.roles.filter(name => name === 'Admin' || name === 'EboAdmin').length > 0){
          return true;
        }else{
          this.router.navigate(['/settings/view']);
        }
      })
    );
  }
}
