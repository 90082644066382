<div class="sidebar-panel">
  <div id="scroll-area" class="navigation-hold" fxLayout="column">
    <!-- App Logo -->
    <div class="branding">
      <!-- <img src="assets/images/logo-ebo-icon-80x80.png" alt="ebo" class="app-logo"> -->
      <!-- Two different logos for dark and light themes -->
<!--      <img src="assets/images/logo-ebo-text-white.png" alt="" class="app-logo-text"-->
<!--           *ngIf="themeService.activatedTheme?.name?.indexOf('dark') !== -1">-->
      <a [routerLink]="['/test/reports/view']">
        <img src="assets/images/logo-ebo-icon-80x80.png" alt="" class="app-logo-small">
      </a>
    </div>
    <!-- Navigation -->
    <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"
                 (closeMenu)="toggleCollapse()"></app-sidenav>
    <div class="sidenav-bottom">
      <button [disableRipple]="true" mat-icon-button id="collapseToggle"
              (click)="toggleCollapse()" class="toggle-collapsed">
        <mat-icon >chevron_left</mat-icon>
      </button>
    </div>
    <span class="app-version">v {{appVersion}}</span>
  </div>
</div>
