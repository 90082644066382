import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// RxJs
import { filter } from 'rxjs/operators';

// Root Singleton Services
import { RoutePartsService, ThemeService } from 'app/core/services';

@Component({
  selector: 'ebo-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'EBO';
  pageTitle = '';
  isIframe = false;

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.changePageTitle();
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
  }
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
      if (routeChange) {
        const getUrl = (routeChange as NavigationEnd).url;
        sessionStorage.setItem(
          'appSettings',
          JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('appSettings')),
            urlLoaded: getUrl
          })
        );
      }

      if ((routeChange as NavigationEnd).url === '/redirect') return;
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);

      if (!routeParts.length) return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map(part => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
