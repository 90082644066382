import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/core/services/http-client.service';
import { Observable, Subject } from 'rxjs';
import { WebUserResponse } from 'app/domain/dtos/configuration/WebUserResponse';
import { map, takeUntil } from 'rxjs/operators';
import { ApiService } from 'app/core/services/api.service';
import { RegisterRequest } from 'app/domain/dtos/configuration/RegisterRequest';
import { UpdateRequest } from 'app/domain/dtos/configuration/UpdateRequest';
import { SetUserStatusRequest } from 'app/domain/dtos/configuration/SetUserStatusRequest';
import { WebUserCompactResponse } from 'app/domain/dtos/configuration/WebUserCompactResponse';
import { RegisterResponse } from 'app/domain/dtos/configuration/RegisterResponse';
import { UpdateResponse } from 'app/domain/dtos/configuration/UpdateResponse';
import { UserActivateRequest } from 'app/domain/dtos/configuration/UserActivateRequest';

@Injectable()
export class UsersService {
  apiUrl: string;
  httpOptions;
  private cancelPendingRequests$ = new Subject<void>();

  constructor(private http: HttpClientService, private apiService: ApiService) {
    this.apiUrl = `${this.apiService.apiUrl}accounts`;
  }

  getUsers(): Observable<WebUserResponse[]> {
    return this.http.get(`${this.apiUrl}/users`, true).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  getUsersCompact(): Observable<WebUserCompactResponse[]> {
    return this.http.get(`${this.apiUrl}/users/compact`, true).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUsersCompactWithExclude(excludeUserId: string): Observable<WebUserCompactResponse[]> {
    return this.http.get(`${this.apiUrl}/users/compact/${excludeUserId}`, true).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUser(id: string): Observable<WebUserResponse> {
    return this.http.get(`${this.apiUrl}/users/id/${id}`, true).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getRoles(): Observable<string[]> {
    return this.http.get(`${this.apiUrl}/users/roles`, true).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUserRoles(userId: string): Observable<string[]> {
    this.cancelPendingRequests(); // Cancel any ongoing request
    this.cancelPendingRequests$ = new Subject<void>(); // Reset the subject

    return this.http.get<string[]>(`${this.apiUrl}/users/roles/${userId}`, true).pipe(
      takeUntil(this.cancelPendingRequests$),
      map((res: any) => {
        return res;
      })
    );
  }

  insertUser(botId: number, registerRequest: RegisterRequest): Observable<RegisterResponse> {
    let body = JSON.stringify(registerRequest);
    return this.http.post<RegisterResponse>(`${this.apiUrl}/users?botId=${botId}`, body, false);
  }

  updateUser(botId: number, updateRequest: UpdateRequest): Observable<UpdateResponse> {
    let body = JSON.stringify(updateRequest);
    return this.http.put<UpdateResponse>(`${this.apiUrl}/users?botId=${botId}`, body, true);
  }

  resetPassword(botId: number, userId: string) {
    let body = JSON.stringify(userId);
    return this.http.put(`${this.apiUrl}/users/resetPassword?botId=${botId}`, body, true);
  }

  enableUser(request: SetUserStatusRequest) {
    let body = JSON.stringify(request);
    return this.http.post(`${this.apiUrl}/users/enable`, body, false);
  }

  activateUser(request: UserActivateRequest) {
    let body = JSON.stringify(request);
    return this.http.post(`${this.apiUrl}/users/activate`, body, false);
  }

  deleteUser(userId: string) {
    return this.http.delete(`${this.apiUrl}/users?userId=${userId}`, true);
  }

  cancelPendingRequests() {
    this.cancelPendingRequests$.next();
    this.cancelPendingRequests$.complete();
  }
}
